@font-face {
    font-family: 'marianne-bold';
    src: url('../fonts/marianne-bold-webfont.woff2') format('woff2'), url('../fonts/marianne-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'marianne-regular';
    src: url('../fonts/marianne-regular-webfont.woff2') format('woff2'), url('../fonts/marianne-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.map {
    margin-top: 10%;
    margin-bottom: 10%;
    height: 400px;
}

.map svg {
    height: initial;
}

:root {
    --primary-color: #00913c;
    --secondary-color: #00e131;
    --bg-color: #fff;
    --h-color: black;
    --p-color: black;
    --label-color: black;
    --msg-info-color: black;
    --btn-hover-color: #01a847;
    --btn-edit-color: #ce3000;
    --btn-edit-hover-color: #ec3700;
    --gh-color: black;
    --gh-link-color: #00913c;
    --label-mi-color: black;
    --validity-color: black;
    --footer-bg-color: #222;
    --footnote-color: black;
    --titre2-color: #00913c;
    --titre3-color: black;
    --titre4-color: black;
    --form-check-label-color: black;
    --form-control-placeholder-color: #ddd;
    --input-bg-color: white;
    --input-color: black;
    --input-border-color: #ddd;
    --form-control-focus-color: white;
    --exemple-color: #999999;
    --covid-title-color: #ffffff;
    --covid-subtitle-color: #ffffff;
    --note-title-border: #fff;
    --footnote-title-color: #fff;
    --msg-alert-color: red;
    --btn-attestation-color: white;
    --main-footer-color: white;
    --footer-line-color: #ffffff;
    --footer-link-hover-color: #0a81ff;
    --footer-link-focus-color: #ffffff;
    --num-08-color: #00913c;
    --num-08-hover-color: #0A81FF;
    --snackbar-color: #fff;
    --snackbar-bg-color: #00913c;
    --snackbar-box-shadow-color: #fff;
    --alert-info-color: #030494;
    --alert-info-bg-color: #caf8ff;
    --alert-info-border-color: #caf8ff;
    --btn-info-color: #fff;
    --btn-info-bg-color: #030494;
    --btn-info-border-color: #030494;
    --error-alert-color: #fff;
    --error-alert-bg-color: #ff0000;
    --error-alert-border-color: #ff0000;
    --fieldset-error-border-color: red;
    --reason-couvrefeu-error-border-color: red;
}

[theme="dark"] {
    --primary-color: #00913c;
    --secondary-color: #00e131be;
    --bg-color: #222;
    --h-color: white;
    --p-color: white;
    --label-color: #DDD;
    --msg-info-color: white;
    --btn-hover-color: #01a847;
    --btn-edit-color: #ce3000;
    --btn-edit-hover-color: #ec3700;
    --gh-color: white;
    --gh-link-color: #00913c;
    --label-mi-color: white;
    --validity-color: #DDD;
    --footer-bg-color: #444;
    --footnote-color: white;
    --titre2-color: #00913c;
    --titre3-color: white;
    --titre4-color: white;
    --form-check-label-color: #ddd;
    --form-control-placeholder-color: #aaa;
    --input-bg-color: #333;
    --input-color: white;
    --input-border-color: #ddd;
    --form-control-focus-color: #555;
    --exemple-color: #999999;
    --covid-title-color: #ffffff;
    --covid-subtitle-color: #ffffff;
    --note-title-border: #fff;
    --footnote-title-color: #fff;
    --msg-alert-color: red;
    --btn-attestation-color: white;
    --main-footer-color: white;
    --footer-line-color: #ffffff;
    --footer-link-hover-color: #0a81ff;
    --footer-link-focus-color: #ffffff;
    --num-08-color: #00913c;
    --num-08-hover-color: #0A81FF;
    --snackbar-color: #fff;
    --snackbar-bg-color: #00913c;
    --snackbar-box-shadow-color: #fff;
    --alert-info-color: #030494;
    --alert-info-bg-color: #caf8ff;
    --alert-info-border-color: #caf8ff;
    --btn-info-color: #fff;
    --btn-info-bg-color: #030494;
    --btn-info-border-color: #030494;
    --error-alert-color: #fff;
    --error-alert-bg-color: #ff0000;
    --error-alert-border-color: #ff0000;
    --fieldset-error-border-color: red;
    --reason-couvre-feu-error-border-color: red;
}

html {
    background: var(--bg-color);
}

@supports (font: -apple-system-body) {
    html {
        font: -apple-system-body !important;
    }
}

body {
    margin: 20px;
    background: var(--bg-color);
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

form,
#alert-print {
    margin: 30px auto;
    max-width: 400px;
}

h1 {
    font-size: 2em;
    color: --h1-color;
}


/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {
    h1 {
        font-size: 2.5em;
    }
}


/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
    h1 {
        font-size: 3em;
    }
}

svg {
    height: 1em;
}

h1.flex.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

h2 {
    font-size: large;
    color: var(--h-color);
}

footer {
    margin-top: 40px;
    background: var(--bg-color);
}

canvas {
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

a {
    color: var(--primary-color);
    text-decoration: underline;
}

p {
    color: var(--p-color);
}

label {
    color: var(--label-color);
}

.wrapper {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

#form-profile .form-radio .form-check {
    margin-bottom: 1rem;
}

#form-profile .form-radio-label .form-check-label {
    font-weight: 400;
    transform: translateY(-2px);
    color: var(--form-check-label-color)
}

.form-control::placeholder {
    color: var(--form-control-placeholder-color);
}

input,
select,
textarea,
.form-control,
.form-control:focus {
    background: var(--input-bg-color);
    color: var(--input-color);
    border: 1px solid var(--input-border-color);
}

.form-control:focus {
    background: var(--form-control-focus-color);
}

#form-generate .form-radio .form-check {
    margin: 10px;
}

#alert-facebook {
    position: fixed;
    top: 20px;
    left: 20px;
    right: 20px;
}

#alert-print {
    margin: 1rem auto 0;
}

#date-selector-group {
    position: relative;
    overflow: hidden;
}

#date-selector-group-sortie {
    position: relative;
    overflow: hidden;
}

#date-selector {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
    /* center the input to avoid reset buttons */
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

#date-selector-sortie {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
    /* center the input to avoid reset buttons */
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

input:invalid+span:after {
    content: '✖';
    padding-left: 0.3em;
}

input:valid+span:after {
    content: '✓';
    padding-left: 0.3em;
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

::placeholder {
    font-style: italic;
}

.hidden {
    display: none !important;
}

.exemple {
    font-style: italic;
    color: var(--exemple-color);
    height: 1em;
}

.logo {
    width: 50%;
}

.covid-title {
    display: flex;
    align-items: center;
    padding: 0.3em;
    font-family: 'marianne-regular', Arial, Helvetica, sans-serif;
    font-size: 0.50em;
    color: var(--covid-title-color);
    background-color: var(--primary-color);
}

@media (min-width: 992px) {
    .covid-title {
        flex-grow: 0;
    }
}

.covid-subtitle {
    display: inline-flex;
    align-items: center;
    font-size: 0.40em;
    padding: 0.3em;
    background-color: var(--secondary-color);
    text-transform: uppercase;
    color: var(--covid-subtitle-color);
    flex-grow: 1;
}

.note-title {
    border-bottom: 3px solid var(--note-title-border);
}

.highlighted {
    font-weight: bold;
    border-bottom: 2px solid;
}

.footnote-title {
    color: var(--footnote-title-color);
    font-size: 0.9em;
}

.msg-info {
    text-align: left;
    color: var(--msg-info-color);
}

.msg-alert {
    text-align: left;
    color: var(--msg-alert-color);
    font-style: italic;
}

.text-desc {
    font-style: italic;
    font-size: 0.8em;
}

.btn-attestation {
    padding: 0.9em;
    font-size: 1.2em;
    font-weight: bold;
    color: var(--btn-attestation-color);
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    border-radius: 0.5em;
}

.btn-attestation:hover {
    border-color: var(--btn-hover-color);
    background-color: var(--btn-hover-color);
}

.btn-edit {
    background-color: var(--btn-edit-color);
    border-color: var(--btn-edit-color);
}

.btn-edit:hover {
    border-color: var(--btn-edit-hover-color);
    background-color: var(--btn-edit-hover-color);
}

.github {
    font-size: 0.7em;
    text-align: center;
    color: var(--gh-color);
}

.disclaimer {
    display: block;
    word-wrap: break-word;
    width: 100%;
    white-space: normal
}

.github-link {
    color: var(--gh-link-color);
}

.github-link:hover {
    text-decoration: underline;
}

.label-mi {
    text-align: center;
    font-size: 1em;
    color: var(--label-mi-color);
}

.validity {
    display: inline-flex;
    justify-content: center;
    width: 2em;
    color: var(--validity-color)
}

.main-footer {
    display: flex;
    justify-content: center;
    padding-top: 3em;
    padding-bottom: 3em;
    background: var(--footer-bg-color);
    color: var(--main-footer-color);
}

.footer-links {
    margin: 0 auto;
    max-width: 500px;
    background: var(--footer-bg-color);
}

.footer-line {
    display: block;
    margin: 0.75em;
    font-size: 0.9em;
    color: var(--footer-line-color);
}

.footer-link:hover {
    text-decoration: underline;
    color: var(--footer-link-hover-color);
}

.footer-link:focus {
    color: var(--footer-link-focus-color);
}

.num-08 {
    font-weight: bold;
    color: var(--num-08-color);
}

.num-08:hover {
    text-decoration: underline;
    color: var(--num-08-hover-color);
}

.stores-link:hover {
    text-decoration: underline;
}

.titre-2 {
    text-align: left;
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--titre2-color);
}

.titre-3 {
    text-align: left;
    font-size: 1.25rem;
    font-weight: bold;
    color: var(--titre3-color);
}

.titre-4 {
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    color: var(--titre4-color);
}

@media (max-width: 800px) {
    table thead {
        display: none;
    }
    table tr {
        display: block;
        margin-bottom: 40px;
    }
    table td {
        display: flex;
        text-align: left;
        min-height: 3rem;
    }
    table td:before {
        content: attr(data-label);
        font-weight: bold;
        color: #000191;
        width: 100px;
        margin-right: 8px;
        flex-shrink: 0;
    }
}

.snackbar {
    min-width: 250px;
    color: var(--snackbar-color);
    background-color: var(--snackbar-bg-color);
    text-align: center;
    border-radius: 3px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    font-size: 17px;
    transform: translateX(-50%);
    box-shadow: 0 0 8px 1px var(--snackbar-box-shadow-color);
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.snackbar.show {
    opacity: 1;
}

@media only screen and (min-width:600px) {
    .hide-on-desktop,
    * [aria-labelledby='hide-on-desktop'] {
        display: none;
        max-height: 0;
        overflow: hidden;
    }
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

[id^="footnote"] {
    margin: 30px auto;
    max-width: 400px;
    font-size: 0.8em;
    color: var(--footnote-color);
}

#update-alert {
    position: fixed;
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
}

.alert-info {
    color: var(--alert-info-color);
    background-color: var(--alert-info-bg-color);
    border-color: var(--alert-info-border-color);
}

.btn-info {
    color: var(--btn-info-color);
    background-color: var(--btn-info-bg-color);
    border-color: var(--btn-info-border-color);
}

.error {
    position: fixed;
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
    color: var(--error-alert-color);
    background-color: var(--error-alert-bg-color);
    border-color: var(--error-alert-border-color);
}

.fieldset {
    margin-left: -2em;
    margin-right: -2em;
    padding-left: 2em;
    padding-right: 2em;
    border: 3px solid transparent;
}

.legend {
    padding-left: 0.5em;
}

.fieldset-error {
    border: 3px solid var(--fieldset-error-border-color);
}

.reason-couvrefeu-error {
    border: 3px solid var(--reason-couvrefeu-error-border-color);
}


/* switch dark mode */

.dark-mode-switch-wrapper {
    justify-content: center;
    display: flex;
    align-items: normal;
    justify-content: center;
}

.dark-mode-switch-wrapper em {
    margin-left: 10px;
    font-size: 1rem;
}

.dark-mode-switch {
    display: inline-block;
    height: 24px;
    position: relative;
    width: 40px;
}

.dark-mode-switch input {
    display: none;
}

.slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
}

.slider:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 17px;
    left: 4px;
    position: absolute;
    transition: .4s;
    width: 17px;
}

input:checked+.slider {
    background-color: var(--primary-color);
}

input:checked+.slider:before {
    transform: translateX(15px);
}

.slider.round {
    border-radius: 24px;
}

.slider.round:before {
    border-radius: 50%;
}